import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import ApiServices from '@kicksplanet/services'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import * as Sentry from '@sentry/browser'
import { CaptureConsole, ContextLines, Debug, ExtraErrorData } from '@sentry/integrations'

import { APP_CONFIG } from '@/configs'
import { lightTheme } from '@/themes/light'

import 'swiper/css/navigation'
import './i18next'

import AppRoute from './components/App/AppRoute'
import AppProvider from './contexts/AppProvider'
import SessionProvider from './contexts/SessionProvider'

import 'swiper/css'

Sentry.init({
  dsn: APP_CONFIG.SENTRY_DSN,
  integrations: [
    new Sentry.Integrations.HttpContext(),
    new CaptureConsole(),
    new ContextLines(),
    new Debug(),
    new ExtraErrorData(),
  ],
  enabled: APP_CONFIG.ENV !== 'local',
  ignoreErrors: [/No refresh token found/, /Missing refresh token/],
  environment: APP_CONFIG.ENV,
})

ApiServices.config({
  apiRoot: APP_CONFIG.API_ROOT,
  apiAddress: APP_CONFIG.ADDRESS_STATIC_API,
  appId: APP_CONFIG.APP_ID,
  walletAPIRoot: APP_CONFIG.WALLET_SERVICE_ROOT,
  invoiceAPIRoot: APP_CONFIG.INVOICE_SERVICE_ROOT,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            },
          },
        })
      }
    >
      <MantineProvider withGlobalStyles withNormalizeCSS theme={lightTheme}>
        <SessionProvider>
          <AppProvider>
            <AppRoute />
          </AppProvider>
        </SessionProvider>
        <Notifications position='top-right' autoClose={5000} />
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
