import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiDownload } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Checkbox, Flex, Menu, Skeleton, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { endOfDay, startOfDay } from 'date-fns'
import { defaultTo } from 'lodash'
import * as XLSX from 'xlsx'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'

import InvoiceListFilter from './Filter'
import InvoiceListRow from './Row'
import { InvoiceListSearchParams } from './types'

const COLUMN_CAPTION = [
  'invoice.orderId',
  'invoice.customerName',
  'invoice.invoiceDate',
  'invoice.total',
  'invoice.exportedAt',
  'invoice.vatPercentage',
]

const Invoices_InvoiceList: React.FC = () => {
  const { t } = useTranslation()
  const invoiceService = useAPIService('Invoice')

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      page: '1',
      per: `${DEFAULT_PAGINATION_QUERY.limit}`,
      key: '',
    }),
  )

  const [checkedItems, setCheckedItems] = useState<Models.Invoice[]>()

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(parseInt(searchParams.get('per') as string), 15),
      key: searchParams.get('key') as string,
      from: (searchParams.get('from') as string) || undefined,
      to: (searchParams.get('to') as string) || undefined,
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    key: keyQuery,
    from: fromQuery,
    to: toQuery,
  } = defaultQueryParams

  const getListQuery = useMemo<Parameters<(typeof invoiceService)['getList']>[0]>(() => {
    return {
      from: searchParams.get('from')
        ? startOfDay(new Date(parseInt(searchParams.get('from') as string))).toISOString()
        : undefined,
      to: searchParams.get('to')
        ? endOfDay(new Date(parseInt(searchParams.get('to') as string))).toISOString()
        : undefined,
      page: pageQuery,
      perPage: perPageQuery,
      q: keyQuery,
    }
  }, [keyQuery, pageQuery, perPageQuery, searchParams])

  const {
    data: invoices,
    isLoading: isLoadingTable,
    refetch: refresh,
  } = useQuery({
    queryKey: ['invoiceService', 'getList', getListQuery],
    queryFn: () => invoiceService.getList(getListQuery),
    select: (res) => res.data,
  })

  const { mutate: exportInvoices } = useMutation({
    mutationFn: () => {
      const clonedSelectedItems = [...(checkedItems ?? [])]
      return invoiceService
        .exportInvoices({
          invoices: clonedSelectedItems.map((item) => item.invoice_id),
        })
        .then(() => clonedSelectedItems)
    },
    onSuccess: (items) => {
      const jsonData = items.map((item, index) => ({
        'Số thứ tự hóa đơn (*)': index + 1,
        'Ngày hóa đơn': formatDate(item.invoice_date, DATE_FORMATS.DD_MM_YYYY),
        'Người mua hàng': item.customer_name,
        'Tên hàng hóa/dịch vụ (*)': item.product_name,
        ĐVT: item.product_unit,
        'Số lượng': item.quantity,
        'Đơn giá': item.unit_price,
        'Thành tiền': item.total,
        'Thuế suất GTGT (%)': item.vat_percentage,
        'Tiền thuế GTGT': (item.total * (item.vat_percentage / 100)).toFixed(2),
        'Mã đơn hàng': item.ref_id,
        'Đã thanh toán': 1,
      }))
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(jsonData)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách hóa đơn')
      XLSX.writeFile(
        workbook,
        `Invoices_${formatDate(new Date(), DATE_FORMATS.YYYY_DD_MM_HH_MM_SS_DASH)}.xlsx`,
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      const errorMessage = t(err?.response?.data, {
        ns: 'backend',
      })

      notifications.show({
        variant: 'danger',
        message: errorMessage,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      err?.response?.data?.errors?.forEach((err: any) => {
        notifications.show({
          variant: 'danger',
          message: t(err, {
            ns: 'backend',
          }),
        })
      })
    },
  })

  const onChangeQueries = useCallback(
    (query: InvoiceListSearchParams) => {
      const newSearchParams = new URLSearchParams(searchParams.toString())
      Object.entries(query).forEach(([key, value]) => {
        newSearchParams.set(key, value)
      })
      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams],
  )

  const onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: Models.Invoice) => {
      const isChecked = event.target.checked

      if (isChecked) {
        if (checkedItems && checkedItems.length > 0) {
          setCheckedItems([...checkedItems, item])
        } else {
          setCheckedItems([item])
        }
      } else {
        setCheckedItems(checkedItems?.filter((x) => x != item))
      }
    },
    [checkedItems],
  )

  const onToggleAllRow = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked

    if (isChecked) {
      setCheckedItems(invoices?.data || [])
    } else {
      setCheckedItems([])
    }
  }

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={7}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!invoices || !invoices.data || invoices.data.length <= 0)
      return (
        <tr>
          <td colSpan={7}>
            <Empty />
          </td>
        </tr>
      )

    return invoices.data.map((item) => {
      return (
        <InvoiceListRow
          key={item.invoice_id}
          invoice={item}
          checkedItems={checkedItems}
          onToggleRow={onToggleRow}
        />
      )
    })
  }, [isLoadingTable, invoices, checkedItems, onToggleRow])

  const onDownloadInvoices = useCallback(() => {
    if (!checkedItems) return
    exportInvoices()
  }, [checkedItems, exportInvoices])

  return (
    <>
      <ListFilterLayout onRefresh={() => refresh()}>
        <InvoiceListFilter
          queryData={{
            key: keyQuery,
            from: fromQuery,
            to: toQuery,
          }}
          onChangeQueries={onChangeQueries}
        />
      </ListFilterLayout>
      <Flex justify='end'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Button
              color='gray.5'
              rightIcon={<IoIosArrowDown size={16} />}
              disabled={!checkedItems || checkedItems.length <= 0}
            >
              {t('common.action')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item>
              <Button variant='subtle' p={4} leftIcon={<BiDownload />} onClick={onDownloadInvoices}>
                <Text>{t('invoice.actions.exportInvoices')}</Text>
              </Button>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <TableWrapper>
        <thead>
          <tr>
            <th>
              <Checkbox
                size='sm'
                checked={Boolean(
                  invoices?.data &&
                    invoices?.data?.length > 0 &&
                    checkedItems &&
                    checkedItems?.length === invoices?.data?.length,
                )}
                onChange={(e) => onToggleAllRow(e)}
              />
            </th>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
    </>
  )
}

export default Invoices_InvoiceList
